<template>
    <pui-form-group
        :label="name"
        :isValid="!isInvalid"
        :showRequiredLabel="isRequired"
    >
        <pui-form-input-field
            :is-valid="!isInvalid"
            v-model="value"
            :placeholder-text="disabled ? '' : placeholder"
            :is-disabled="disabled"
            :type="type"
            :trailing-icon-name="iconName"
            :trailing-icon-is-clickable="iconName !== ''"
            @blur="onBlur()"
            @trailing-icon:click="onIconClicked()"
            :max-length="characterLimit"
        />
        <template #error-message>
            {{ error }}
        </template>
        <template #helper-text>
            {{ helperText }}
        </template>
    </pui-form-group>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'text-input'
})

export default class TextInput extends Vue {
    @Prop({required: false, default: '' })
    inputValue!: any;

    @Prop({required: false, default: '' })
    name!: any;

    @Prop({required: false })
    readonly placeholder!: string;

    @Prop({required: false, default: false })
    disabled!: any;

    @Prop({required: false, default: 'text' })
    type!: any;

    @Prop({required: false })
    helperText!: string;

    @Prop({required: false })
    error!: string;

    @Prop({required: false })
    isInvalid!: boolean;

    @Prop({required: false })
    isRequired!: boolean;

    @Prop({required: false })
    iconName!: string;

    @Prop({required: false })
    characterLimit?: number;

    get value(): string {
        return this.inputValue === null || this.inputValue === undefined
            ? ''
            : `${this.inputValue}`;
    }

    set value(changedValue: string ) {
        switch(this.type) {
        case 'text':
            this.$emit('change', changedValue);
            break;
        case 'number':
            if (this.valueContainsMultipleZeros(changedValue)) changedValue = '';
            changedValue === ''
                ? this.$emit('change', undefined)
                : this.$emit('change', Number(changedValue));
            break;
        default:
            break;
        }
    }

    private valueContainsMultipleZeros(value: string): boolean {
        const arrayOfChar = value.split('');
        return arrayOfChar.length > 1
            && arrayOfChar.every((char: string) => char === '0');
    }

    private onBlur(): void {
        this.$emit('blur');
    }

    private onIconClicked(): void {
        this.$emit('iconClicked');
    }
}
</script>
