<template>
    <div class="mt-2 ml-4 mr-4">
        <use-case />
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import UseCase from '@/components/use-case/use-case.vue';

@Component({
    name: 'home',
    components: {
        UseCase,
    }
})
export default class Home extends Vue {
}

</script>

<style lang="less" scoped>
</style>
