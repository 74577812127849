import { Workflow, WorkflowType, UseCaseItem, Icon } from '@/models';
import { iconService } from '@/services';
import { EventBus } from '@/utils';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const applicationState: {
    isLoading: boolean;
    workflows: Workflow[];
    workflowTypes: WorkflowType[];
    useCases: UseCaseItem[];
    icons: Icon[];
} = {
    isLoading: false,
    workflows: [] as Workflow[],
    useCases: [] as UseCaseItem[],
    workflowTypes: [] as WorkflowType[],
    icons: [] as Icon[],
};

export default new Vuex.Store({
    state: applicationState,
    mutations: {
        loading(state): void {
            state.isLoading = !state.isLoading;
        },
        setWorkflows(state: any, workflows: Workflow[]): void {
            state.workflows = workflows;
        },
        setWorkflowTypes(state: any, workflowTypes: WorkflowType[]): void {
            state.workflowTypes = workflowTypes;
        },
        setUseCases(state: any, useCases: UseCaseItem[]): void {
            state.useCases = useCases;
        },
        setIcons(state: any, icons: Icon[]): void {
            state.icons = icons;
        }
    },
    actions: {
        async loadIcons({ commit }): Promise<void> {
            try {
                const r = await iconService.getAllIcons();
                if(!r) {
                    return;
                }
                commit('setIcons', r);
            } catch(error) {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, error);
            }
        },
        loadWorkflows: ({ commit }: any, payload: any): void => {
            commit('setUseCases', payload.useCases);
            commit('setWorkflowTypes', payload.workflowTypes);
            commit('setWorkflows', payload.workflows);
        },
    },
    modules: {},
    getters: {
        isLoading(state): boolean {
            return state.isLoading;
        },
        workflows(state): Workflow[] {
            return state.workflows;
        },
        workflowTypes(state): WorkflowType[] {
            return state.workflowTypes;
        },
        useCases(state): UseCaseItem[] {
            return state.useCases;
        },
        icons(state): Icon[] {
            return state.icons;
        }
    },
});
