<template>
    <div class="flex-row flex-center">
        <pui-form-input-field
            v-model="searchTerm"
            class="table-search"
            :trailing-icon-name="iconName"
            :trailing-icon-is-clickable="true"
            :placeholder-text="$t('searchByKeyword')"
            @trailing-icon:click="clearInput"
            @keyup.native="onKeyUp"
        />
        <span
            class="search-button"
            @click="filterByKeyword"
        >
            <pui-icon
                icon-color="white"
                icon-name="search"
                size="16"
            />
        </span>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Emit } from 'vue-property-decorator';

@Component({})
export default class SearchBar extends Vue {
    searchTerm = '';
    searchPressed = false;

    @Emit()
    filterByKeyword(): string {
        this.searchPressed = true;
        return this.searchTerm;
    }

    private onKeyUp(event: any): void {
        if (event?.key === 'Enter') {
            this.filterByKeyword();
        }
    }

    private clearInput(): void {
        this.searchTerm = '';
        if(this.searchPressed)
            this.filterByKeyword();
        this.searchPressed = false;
    }

    get iconName(): string {
        if(this.searchTerm.length > 0)
            return 'close';
        return '';
    }
}
</script>

<style lang="less" scoped>
.table-search {
    width: 30rem;
    border-radius: 0.4rem 0 0 0.4rem;
}

.search-button {
    cursor: pointer;
    background-color: #00426c;
    border-radius: 0 0.4rem 0.4rem 0;
    width: 5rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: darken(#00426c, 2%);
    }
}
</style>
