export class Icon {
    id: number;
    downloadPath: string;
    name: string;

    public constructor(data?: Icon | any) {
        this.id = data?.id;
        this.downloadPath = data?.downloadPath;
        this.name = data?.name;
    }
}
