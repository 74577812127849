import { render, staticRenderFns } from "./tags-input.vue?vue&type=template&id=8270b816&scoped=true"
import script from "./tags-input.vue?vue&type=script&lang=ts"
export * from "./tags-input.vue?vue&type=script&lang=ts"
import style0 from "./tags-input.vue?vue&type=style&index=0&id=8270b816&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8270b816",
  null
  
)

export default component.exports