import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/components/home/home.vue';
import WorkflowView from '@/components/workflow/workflow-view.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'Use Case',
        component: Home,
        // meta: {
        //     requiresAuthentication: true,
        // },
        // component: () => import(/* webpackChunkName: "Home" */ '../components/home/home.vue'),
    },
    {
        path: '/workflows',
        name: 'Workflows',
        component: WorkflowView,
        meta: {
            requiresAuthentication: true,
        },
    },
];

const router = new VueRouter({
    routes,
});


export default router;
