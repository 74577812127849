export const APPLICATION_TYPE = [
    {
        label: 'webApplication',
        type: 'webApplication',
        value: 1,
    },
    {
        label: 'reportApplication',
        type: 'reportApplication',
        value: 2,
    },
    {
        label: 'powerAppApplication',
        type: 'powerAppApplication',
        value: 3,
    },
    {
        label: 'mobileApplication',
        type: 'mobileApplication',
        value: 4,
    },
];

export const DEFAULT_APP_TYPE = APPLICATION_TYPE[0];
