import { UseCaseModel, ApiResponse, UseCaseItem } from '@/models';
import { sdk } from '@/utils/sdk';

export class UseCaseService {
    private endpoint = 'use-cases';

    public async get(): Promise<ApiResponse<UseCaseModel>> {
        return (await sdk?.backend.request.api.get<ApiResponse<UseCaseModel>>(`${this.endpoint}`)).data;
    }
    public async add(useCase: UseCaseItem): Promise<ApiResponse<UseCaseModel>> {
        return (await sdk?.backend.request.api.post<ApiResponse<UseCaseModel>>(`${this.endpoint}`, useCase)).data;
    }
    public async update(id: number, useCase: UseCaseItem): Promise<ApiResponse<UseCaseModel>> {
        return (await sdk?.backend.request.api.put<ApiResponse<UseCaseModel>>(`${this.endpoint}/${id}`, useCase)).data;
    }
    public async delete(id: number): Promise<ApiResponse<UseCaseItem>> {
        return (await sdk?.backend.request.api.delete<ApiResponse<UseCaseItem>>(`${this.endpoint}/${id}`)).data;
    }
}
