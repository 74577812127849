import { ApiResponse } from '@/models';
import { Icon } from '@/models/icon';
import store from '@/store';
import { sdk } from '@/utils/sdk';

class IconService {
    private endpoint = 'icons';

    public async getAllIcons(): Promise<any> {
        try {
            const icons = (await sdk?.backend.request.api.get<any>(
                `${this.endpoint}`
            ) as any).data.result.data;
            return icons?.map((icon: Icon) => new Icon(icon));
        }
        catch (error) {
            return [];
        }
    }

    public async uploadIcon(file: any): Promise<boolean> {
        store.commit('loading');
        try {
            const formData = new FormData();
            formData.append('File', file.data, file.fileName ? file.fileName : 'File');
            await sdk?.backend.request.api.postForm(
                this.endpoint,
                formData,
            );
            return true;
        }
        catch (error) {
            return false;
        } finally {
            store.commit('loading');
        }
    }
}

export const iconService = new IconService();
