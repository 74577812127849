var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isDataLoaded)?_c('div',{staticClass:"mt-2 ml-4"},[_c('h3',[_vm._v(_vm._s(_vm.$t('configureWorkflows')))]),_c('pui-tabs',{staticClass:"mt-4",attrs:{"selected":_vm.selectedTab},on:{"changed":_vm.onTabChanged}},_vm._l((_vm.tabs),function(tab){return _c('pui-tab',{key:tab.title,staticClass:"mt-4 mr-4",attrs:{"title":tab.title}},[_c('div',[(_vm.tableConfig.data.length > 0)?_c('pui-table',{staticClass:"mb-2",attrs:{"vendor-options":_vm.tableConfig},scopedSlots:_vm._u([{key:_vm.tableColumnHeaders.id,fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.index + 1)+" ")]}},{key:_vm.tableColumnHeaders.workflow,fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.displayName)+" ")]}},{key:_vm.tableColumnHeaders.useCase,fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getUseCaseNameById(row.useCaseId))+" ")]}},{key:_vm.tableColumnHeaders.actions,fn:function(ref){
var row = ref.row;
return [(_vm.selectedTab === 0)?_c('div',{staticClass:"flex-row space-between"},[_c('pui-button',{staticClass:"mr-4",attrs:{"state":"secondary","small":"","icon":"settings"},on:{"click":function($event){return _vm.openSettingsModal(row)}}},[_vm._v(" "+_vm._s(_vm.$t("settings"))+" ")]),_c('pui-button',{attrs:{"state":"secondary","small":"","icon":"delete"},on:{"click":function($event){return _vm.openUnassociationModal(row)}}},[_vm._v(" "+_vm._s(_vm.$t("unassociate"))+" ")])],1):_c('pui-button',{staticClass:"mr-4",attrs:{"state":"secondary","small":"","icon":"add"},on:{"click":function($event){return _vm.openSettingsModal(row)}}},[_vm._v(" "+_vm._s(_vm.$t("associate"))+" ")])]}}],null,true)}):_c('pui-loader-error',{staticClass:"mt-2 mb-2",attrs:{"title":_vm.$t('noWorkflowsFound'),"icon":"error-empty-data"}})],1)])}),1),_c('workflow-edit-modal',{ref:"editWorkflowModal",attrs:{"workflow":_vm.selectedWorkflow},on:{"associateWorkflowEvent":_vm.associateUsecaseWithWorkflow}}),_c('confirm-modal',{ref:"unassociateConfirmationModal",on:{"confirm":function($event){return _vm.unAssociateUsecaseWithWorkflow(_vm.selectedWorkflow)}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }