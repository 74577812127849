<template>
    <div>
        <pui-tooltip />
        <div
            v-pui-tooltip="{ message: tooltip }"
        >
            <pui-form-group
                :label="name"
                :has-info-layer="showPopover"
                :pui-popover-config="popoverConfig"
                :showRequiredLabel="isRequired"
                :isValid="!isInvalid"
            >
                <pui-form-select
                    :class="disabled ? 'readonly-input': ''"
                    :key="selectBoxOptions.length"
                    :is-valid="!isInvalid"
                    :is-disabled="disabled"
                    v-model="value"
                    label=""
                    :options="selectBoxOptions"
                    :search-input-placeholder="disabled ? ' ' : placeholder"
                />
                <template #error-message>
                    {{ error }}
                </template>
                <template #pui-popover-content>
                    <div class="popover-content">
                        {{ helperText }}
                    </div>
                </template>
            </pui-form-group>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'select-box'
})

export default class SelectBox extends Vue {
    @Prop({required: false, default: '' })
    inputValue!: any;

    @Prop({required: false, default: '' })
    name!: any;

    @Prop({default: () => []})
    readonly selectBoxOptions!: Array<any>;

    @Prop({required: false, default: false })
    disabled!: any;

    @Prop({required: false })
    readonly placeholder!: string;

    @Prop({required: false, default: '' })
    readonly helperTitle!: string;

    @Prop({required: false, default: '' })
    readonly helperText!: string;

    @Prop({required: false })
    error!: string;

    @Prop({required: false })
    isRequired!: boolean;

    @Prop({required: false })
    isInvalid!: boolean;

    get value(): string {
        return this.inputValue;
    }

    set value(changedValue: string) {
        this.$emit('change', changedValue);
    }

    private get showPopover(): boolean {
        return !!this.helperTitle || !!this.helperText;
    }

    private popoverConfig = {
        headline: this.helperTitle,
        position: 'bottom',
        'has-close-icon': false
    }

    get tooltip(): string {
        if(this.disabled && this.inputValue >= 0 && this.selectBoxOptions.length) {
            return this.selectBoxOptions[this.inputValue]?.label;
        }
        return '';
    }
}
</script>

<style lang="less" scoped>
.popover-content {
   white-space: pre-line;
}

/deep/ .readonly-input {
    input {
        padding: 0.8rem 1.2rem;
    }
    svg {
        display: none;
    }
}
</style>
