<template>
    <pui-lightbox
        :default-header-label="$t('settings')"
        default-trigger-label=""
        :default-footer-confirm-label="$t('save')"
        :default-footer-cancel-label="$t('cancel')"
        :fit-content="true"
        :fullscreen="false"
        :onConfirmCallback="associateUsecaseWithWorkflow"
        ref="associateWorkflowRef"
    >
        <template #default>
            <pui-form-group label="Workflow:" labelFor="workflowname-input">
                <pui-form-input-field
                    id="workflowname-input"
                    :value="workflow.id"
                    :is-readonly="true"
                    :is-disabled="true"
                    :is-valid="true"
                />
            </pui-form-group>
            <pui-form-group
                :label="$t('usecase')"
                labelFor="usecase-associate-input"
            >
                <pui-form-select
                    label="Associate Use Case"
                    :options="useCases"
                    :value="selectedUseCase"
                    :is-valid="true"
                    :is-readonly="false"
                    :is-disabled="false"
                    @change="useCaseChanged"
                />
            </pui-form-group>
        </template>
    </pui-lightbox>
</template>

<script lang="ts">
import { UseCaseItem, WorkflowType } from '@/models';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'workflow-edit-modal',
})
export default class WorkflowEditModal extends Vue {
  @Prop({ required: true })
  private workflow!: WorkflowType;

  private newSelectedUseCaseOption = -1;

  private associateUsecaseWithWorkflow(): void {
      this.$emit('associateWorkflowEvent', {
          workflowDefinitionId: this.workflow.id,
          newUseCaseId: this.newSelectedUseCaseOption,
      });
      (this.$refs.associateWorkflowRef as any).isOpen = false;
  }

  public open(): void {
      (this.$refs.associateWorkflowRef as any)?.open();
  }

  get selectedUseCase(): any {
      if(this.workflow.useCaseId)
          return this.useCases.filter((useCase: any) => useCase.value.startsWith(this.workflow?.useCaseId))[0].value;
      return undefined;
  }

  private useCaseChanged(option: string): void {
      this.newSelectedUseCaseOption = Number(option.split('-')[0]);
  }

  get useCases(): any[] {
      return this.$store.getters.useCases.map((u: UseCaseItem) => ({
          label: u.name,
          value: `${u.id}-${u.name}`,
      }));
  }
}
</script>
