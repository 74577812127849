import { CoodeSdk, CoodeSdkOptions, Environments } from '@coode/fe-sdk';

const coodeSdkPluginConfiguration: CoodeSdkOptions = {
    environment: process.env.VUE_APP_ENVIRONMENT as Environments,
    auth: {
        backEndBaseUrl: process.env.VUE_APP_API_BASE_URL as string,
        backEndScope: process.env.VUE_APP_META_DATA_SCOPE as string,
        msal: {
            auth: {
                clientId: process.env.VUE_APP_CLIENT_ID || '',
                authority: process.env.VUE_APP_AUTHORITY_URL,
                redirectUri: window.location.origin,
                postLogoutRedirectUri: `${window.location.origin}/authorization/logged-out.html`,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
            },
            system: {
                loggerOptions: 0 as any,
            },
        },
    },
    insights: {
        appInsightsConnectionString: process.env.VUE_APP_APPINSIGHT_CONNECTION_STRING as string,
    }
};

export const sdk = new CoodeSdk(coodeSdkPluginConfiguration);
