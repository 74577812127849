<template>
    <pui-lightbox
        ref="confirmation"
        :show-lightbox-close-icon="false"
        :default-header-label="$t('pleaseConfirm')"
        :default-footer-confirm-label="$t('yes')"
        :default-footer-cancel-label="$t('no')"
        :fit-content="true"
        :on-confirm-callback="confirm"
        @puilightbox:close="close"
    >
        <template #default>
            {{ confirmationMessage }}
        </template>
    </pui-lightbox>
</template>

<script lang="ts">
import { Component, Vue, Emit } from 'vue-property-decorator';

@Component({
    name: 'confirm-modal',
})
export default class ConfirmModal extends Vue {
    private confirmationMessage = '';

    public open(message = ''): void {
        this.confirmationMessage = message;
        (this.$refs.confirmation as any)?.open();
    }

    private close(): void {
        (this.$refs.confirmation as any)?.close();
        this.confirmationMessage = '';
    }

    @Emit()
    confirm(): void {
        this.close();
    }
}
</script>
