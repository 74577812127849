import { ApiResponse } from '@/models';
import { RelatedIdea } from '@/models/related-idea';
import { sdk } from '@/utils/sdk';

export class RelatedUseCaseService {

    public async getRelatedIdeasOfUseCase(useCaseId: number): Promise<RelatedIdea[]> {
        return (await sdk?.core.metaData.request.api.get<ApiResponse<{useCases: RelatedIdea[]}>>(
            `use-case/${useCaseId}/ideas`
        ))?.data?.result?.useCases;
    }
}
