import { ApiResponse, Workflow, WorkflowApiResponse, WorkflowTypeApiResponse } from '@/models';
import { sdk } from '@/utils/sdk';

export class WorkflowService {
    private endpoint = 'workflow-type-associations';
    
    public async get(): Promise<ApiResponse<WorkflowApiResponse>> {
        return (await sdk?.backend.request.api.get<ApiResponse<WorkflowApiResponse>>(`${this.endpoint}`)).data;
    }
    public async getTypes(): Promise<ApiResponse<WorkflowTypeApiResponse>> {
        return (await sdk?.backend.request.api.get<ApiResponse<WorkflowTypeApiResponse>>(`workflow-types`)).data;
    }
    public async add(workflow: any): Promise<ApiResponse<WorkflowApiResponse>> {
        return (await sdk?.backend.request.api.post<ApiResponse<WorkflowApiResponse>>(`${this.endpoint}`, { useCaseId: workflow.useCaseId, workflowDefinitionId: workflow.workflowDefinitionId })).data;
    }
    public async update(id: number, useCaseId: number): Promise<ApiResponse<WorkflowApiResponse>> {
        return (await sdk?.backend.request.api.put<ApiResponse<WorkflowApiResponse>>(`${this.endpoint}/${id}`, { useCaseId: useCaseId })).data;
    }
    public async delete(id: number): Promise<ApiResponse<Workflow>> {
        return (await sdk?.backend.request.api.delete<ApiResponse<Workflow>>(`${this.endpoint}/${id}`)).data;
    }
}
