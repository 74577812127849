<template>
    <div v-if="isDataLoaded" class="mt-2 ml-4">
        <h3>{{ $t('configureWorkflows') }}</h3>
        <pui-tabs
            :selected="selectedTab"
            @changed="onTabChanged"
            class="mt-4"
        >
            <pui-tab
                v-for="tab in tabs"
                :key="tab.title"
                :title="tab.title"
                class="mt-4 mr-4"
            >
                <div>
                    <pui-table
                        v-if="tableConfig.data.length > 0"
                        :vendor-options="tableConfig"
                        class="mb-2"
                    >
                        <template
                            :slot="tableColumnHeaders.id"
                            slot-scope="{ row }"
                        >
                            {{ row.index + 1 }}
                        </template>
                        <template
                            :slot="tableColumnHeaders.workflow"
                            slot-scope="{ row }"
                        >
                            {{ row.displayName }}
                        </template>
                        <template
                            :slot="tableColumnHeaders.useCase"
                            slot-scope="{ row }"
                        >
                            {{ getUseCaseNameById(row.useCaseId) }}
                        </template>
                        <template
                            :slot="tableColumnHeaders.actions"
                            slot-scope="{ row }"
                        >
                            <div v-if="selectedTab === 0" class="flex-row space-between">
                                <pui-button
                                    class="mr-4"
                                    state="secondary"
                                    small
                                    icon="settings"
                                    @click="openSettingsModal(row)"
                                >
                                    {{ $t("settings") }}
                                </pui-button>
                                <pui-button
                                    state="secondary"
                                    small
                                    icon="delete"
                                    @click="openUnassociationModal(row)"
                                >
                                    {{ $t("unassociate") }}
                                </pui-button>
                            </div>
                            <pui-button
                                v-else
                                class="mr-4"
                                state="secondary"
                                small
                                icon="add"
                                @click="openSettingsModal(row)"
                            >
                                {{ $t("associate") }}
                            </pui-button>
                        </template>
                    </pui-table>
                    <pui-loader-error
                        v-else
                        :title="$t('noWorkflowsFound')"
                        icon="error-empty-data"
                        class="mt-2 mb-2"
                    />
                </div>
            </pui-tab>
        </pui-tabs>
        <workflow-edit-modal
            ref="editWorkflowModal"
            @associateWorkflowEvent="associateUsecaseWithWorkflow"
            :workflow="selectedWorkflow"
        />
        <confirm-modal
            ref="unassociateConfirmationModal"
            @confirm="unAssociateUsecaseWithWorkflow(selectedWorkflow)"
        />
    </div>
</template>

<script lang="ts">
import { Workflow, WorkflowType } from '@/models';
import { Component, Vue } from 'vue-property-decorator';
import WorkflowEditModal from './partials/workflow-edit-modal.vue';
import { UseCaseService, WorkflowService } from '@/services';
import { UseCaseItem } from '@/models';
import { EventBus } from '@/utils';
import ConfirmModal from '../confirm-modal/confirm-modal.vue';

@Component({
    name: 'workflow-view',
    components: {
        WorkflowEditModal,
        ConfirmModal,
    },
})
export default class WorkflowView extends Vue {
  private useCaseService: UseCaseService = new UseCaseService();
  private workflowService: WorkflowService = new WorkflowService();
  private isDataLoaded = false;
  private selectedTab = 0;
  private selectedWorkflow = null;

  get useCases(): any[] {
      return this.$store.getters.useCases.map((u: UseCaseItem) => ({
          label: u.name,
          value: u.id,
      }));
  }

  get workflows(): any[] {
      return this.$store.getters.workflows;
  }

  get workflowTypes(): any[] {
      return this.$store.getters.workflowTypes;
  }

  get tabs(): any[] {
      return [
          {
              title: `${this.$t('workflowType.associated')} (${
                  this.associatedWorkflows.length
              })`,
              content: this.associatedWorkflows,
          },
          {
              title: `${this.$t('workflowType.unAssociated')} (${
                  this.unAssociatedWorkflows.length
              })`,
              content: this.unAssociatedWorkflows,
          },
      ];
  }

  get associatedWorkflows(): any[] {
      return this.workflowTypes.filter((workflowType: WorkflowType) => workflowType.useCaseId).map(
          (workflow: Workflow, index: number) => ({...workflow, index} )
      );
  }

  get unAssociatedWorkflows(): any[] {
      return this.workflowTypes.filter((workflowType: WorkflowType) => !workflowType.useCaseId).map(
          (workflow: Workflow, index: number) => ({...workflow, index} )
      );
  }

  private mounted(): void {
      this.init();
  }

  private async init(): Promise<void> {
      this.loadWorkflows();
  }

  private async loadWorkflows(): Promise<void> {
      this.$store.commit('loading');
      try {
          this.isDataLoaded = false;
          const useCases = (await this.useCaseService.get()).result.items;
          const workflowTypes = (await this.workflowService.getTypes()).result
              .items;
          const workflows = (await this.workflowService.get()).result.items;
          this.$store.dispatch('loadWorkflows', {
              useCases,
              workflows,
              workflowTypes,
          });
          this.isDataLoaded = true;
      } catch (error) {
          this.isDataLoaded = false;
          EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingWorkflows');
          throw error;
      } finally {
          this.$store.commit('loading');
      }
  }

  private async associateUsecaseWithWorkflow(associateWorkflowObj: any): Promise<void> {
      try {
          const foundAssociationToType = this.workflows.find(
              (w: Workflow) =>
                  w.workflowDefinitionId === associateWorkflowObj.workflowDefinitionId
          );

          if (foundAssociationToType) {
              await this.workflowService.update(
                  foundAssociationToType.id,
                  associateWorkflowObj.newUseCaseId
              );
          } else {
              await this.workflowService.add(
                  {
                      useCaseId: associateWorkflowObj.newUseCaseId,
                      workflowDefinitionId: associateWorkflowObj.workflowDefinitionId
                  }
              );
          }
      } catch (error) {
          EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorAssociatingWorkflow');
          throw error;
      } finally {
          this.loadWorkflows();
      }
  }

  private async unAssociateUsecaseWithWorkflow(workflow: any): Promise<void> {
      try {
          const foundAssociationToType = this.workflows.find(
              (w: Workflow) => w.workflowDefinitionId === workflow.type);
          await this.workflowService.delete(foundAssociationToType.id);
      } catch (error) {
          EventBus.$emit(
              EventBus.GLOBAL.SHOW_SNACKBAR,
              'errorUnAssociatingWorkflow'
          );
          throw error;
      } finally {
          this.loadWorkflows();
      }
  }

  private onTabChanged(idx: number): void {
      this.selectedTab = idx;
  }

  private tableColumnHeaders = {
      id: this.$t('id') as string,
      workflow: this.$t('workflow') as string,
      useCase: this.$t('usecase') as string,
      actions: this.$t('actions') as string
  };

  get tableConfig(): any {
      return {
          options: {
              sortable: [],
              perPage: this.selectedTab === 0 ? this.associatedWorkflows.length : this.unAssociatedWorkflows.length
          },
          columns: Object.values(this.tableColumnHeaders),
          data: this.selectedTab === 0 ? this.associatedWorkflows : this.unAssociatedWorkflows,
      };
  }

  private getUseCaseNameById(id: number): string | undefined {
      return this.useCases.find((u: any) => u.value == id)?.label || '-';
  }

  private openSettingsModal(workflow: any): void {
      this.selectedWorkflow = workflow;
      (this.$refs.editWorkflowModal as WorkflowEditModal).open();
  }

  private openUnassociationModal(workflow: any): void {
      this.selectedWorkflow = workflow;
      (this.$refs.unassociateConfirmationModal as ConfirmModal).open(
          this.$t('areYouSureToUnassociateWorkflow') + ' ' + workflow.displayName + ' ?'
      );
  }
}
</script>

<style lang="less" scoped>
</style>
