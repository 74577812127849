<template>
    <pui-lightbox
        ref="useCaseModal"
        :show-lightbox-close-icon="true"
        :default-header-label="modalName"
        :default-footer-confirm-label="$t('save')"
        :default-footer-cancel-label="$t('cancel')"
        :fit-content="false"
        :on-confirm-callback="saveUseCase"
        @puilightbox:close="close"
    >
        <template #default>
            <pui-collapsible
                class="header"
                :headline="$t('useCaseModal.applicationInformation')"
            >
                <div v-pui-form-grid-row>
                    <div v-pui-form-grid-column="6">
                        <text-input
                            :name="$t('name')"
                            :placeholder="$t('nameMaxLength', {maxLength: nameMaxLength})"
                            :input-value="useCase.name"
                            @change="handleNameInput"
                            :isInvalid="isInvalid('repositoryNameRequired')"
                            :error="$t('useCaseModal.nameIsRequired')"
                            :isRequired="isAdd"
                            :characterLimit="nameMaxLength"
                        />
                    </div>
                    <div v-pui-form-grid-column="6">
                        <text-input
                            :name="$t('identityClientId')"
                            :placeholder="$t('identityClientId')"
                            :input-value="useCase.identityClientId"
                            @change="handleClientIdInput"
                            :isInvalid="isInvalid('identityClientIdRequired') || isInvalid('identityClientIdIsUnique')"
                            :error="$t('useCaseModal.identityClientIdIsRequired')"
                            :isRequired="isAdd"
                            :disabled="isEditUseCaseMode"
                        />
                    </div>
                </div>
                <div v-pui-form-grid-row>
                    <div
                        v-pui-form-grid-column="12"
                        class="section-margin"
                        @mouseover="setShortDescriptionInputOutline(true)"
                        @mouseleave="setShortDescriptionInputOutline(false)"
                    >
                        <span class="input-label">
                            <span>{{ $t("shortDescription") + ' ' }}</span>
                            <span v-if="isAdd" class="required">{{ $t('useCaseModal.required') }}</span>
                        </span>

                        <rich-text-editor
                            :class="['label-margin', 'textarea-style',
                                     {invalid: isInvalid('repositoryShortDescriptionRequired')},
                                     {focused: isShortDescriptionFocused}]"
                            :value="useCase.shortDescription"
                            :characterLimit="shortDescriptionMaxLength"
                            :placeholder="$t('shortDescription')"
                            :isInvalid="isInvalid('repositoryShortDescriptionRequired')"
                            @input="handleShortDescriptionInput"
                            @focus="setShortDescriptionInputOutline(true)"
                            @blur="setShortDescriptionInputOutline(false)"
                        />
                        <div
                            class="invalid-text"
                            v-if="isInvalid('repositoryShortDescriptionRequired')"
                        >
                            {{ $t('useCaseModal.shortDescriptionIsRequired') }}
                        </div>
                    </div>
                </div>
                <div v-pui-form-grid-row>
                    <div
                        v-pui-form-grid-column="12"
                        class="section-margin"
                        @mouseover="setDescriptionInputOutline(true)"
                        @mouseleave="setDescriptionInputOutline(false)"
                    >
                        <span class="input-label">
                            <span>{{ $t("description") + ' ' }}</span>
                            <span v-if="isAdd" class="required">{{ $t('useCaseModal.required') }}</span>
                        </span>
                        <rich-text-editor
                            :class="['label-margin', 'textarea-style',
                                     {invalid: isInvalid('repositoryDescriptionRequired')},
                                     {focused: isDescriptionFocused}]"
                            :value="useCase.description"
                            :characterLimit="descriptionMaxLength"
                            :placeholder="$t('description')"
                            :isInvalid="isInvalid('repositoryDescriptionRequired')"
                            @input="handleDescriptionInput"
                            @focus="setDescriptionInputOutline(true)"
                            @blur="setDescriptionInputOutline(false)"
                        />
                        <div
                            class="invalid-text"
                            v-if="isInvalid('repositoryDescriptionRequired')"
                        >
                            {{ $t('useCaseModal.descriptionIsRequired') }}
                        </div>
                    </div>
                </div>
                <div v-pui-form-grid-row>
                    <div v-pui-form-grid-column="12">
                        <text-input
                            :name="$t('url')"
                            :placeholder="$t('url')"
                            :input-value="useCase.url"
                            :isInvalid="isUrlFieldRequired ? isInvalid('repositoryURLRequired') : false"
                            :error="isUrlFieldRequired ? $t('useCaseModal.urlIsRequired') : ''"
                            :isRequired="isUrlFieldRequired"
                            @change="handleURLInput"
                        />
                    </div>
                </div>
                <div v-pui-form-grid-row>
                    <div v-pui-form-grid-column="6">
                        <logo-selector
                            :selectedLogoId="useCase.logoId"
                            :selectedLogoName="useCase.logoName"
                            :selectedLogoUrl="useCase.logoUrl"
                            @change="handleIconChange"
                        />
                    </div>
                    <select-box
                        v-pui-form-grid-column="6"
                        :name="$t('status')"
                        :placeholder="$t('pleaseSelect')"
                        :select-box-options="useCaseStatuses"
                        :input-value="applicationStatus"
                        @change="value => useCase.status = parseInt(value)"
                    />
                </div>
                <div v-pui-form-grid-row>
                    <select-box
                        v-pui-form-grid-column="6"
                        :name="$t('applicationType')"
                        :placeholder="$t('pleaseSelect')"
                        :select-box-options="applicationTypes"
                        :input-value="applicationType"
                        @change="value => useCase.applicationType = value"
                    />
                    <select-box
                        v-pui-form-grid-column="6"
                        :name="$t('mainCategory')"
                        :placeholder="$t('pleaseSelect')"
                        :select-box-options="categories"
                        :input-value="useCase.mainCategoryId"
                        :isRequired="isAdd"
                        :isInvalid="isInvalid('mainCategoryRequired')"
                        :error="$t('useCaseModal.mainCategoryIsRequired')"
                        @change="handleChangeMainCateg"
                    />
                </div>
                <div v-pui-form-grid-row>
                    <multi-select
                        v-pui-form-grid-column="12"
                        :name="$t('secondaryCategories')"
                        :placeholder="$t('pleaseSelect')"
                        :select-box-options="secondaryCategories"
                        :input-value="selectedSecondaryCategories"
                        @change="value => selectedSecondaryCategories = value"
                        :rules="{}"
                    />
                </div>
                <div v-pui-form-grid-row>
                    <multi-select
                        v-pui-form-grid-column="12"
                        :name="$t('technology')"
                        :placeholder="$t('pleaseSelect')"
                        :isRequired="isAdd"
                        :select-box-options="technologies"
                        :input-value="selectedTechnologies"
                        :isInvalid="isInvalid('repositoryTechnologiesRequired')"
                        :error="$t('useCaseModal.technologyIsRequired')"
                        @change="handleChangeTechnologies"
                        :rules="{}"
                    />
                </div>
                <div v-pui-form-grid-row>
                    <pui-form-group
                        v-pui-form-grid-column="12"
                        :label="$t('owner')"
                        :is-valid="false"
                    >
                        <pui-form-type-ahead
                            :label="$t('owner')"
                            :search-input-id="'owner'"
                            v-model="selectedOwner"
                            :is-loading="loadingResults"
                            :options="userSuggestionList"
                            :max-visible-options="10"
                            :search-input-debounce-time="400"
                            :is-disabled="false"
                            :is-readonly="false"
                            :use-local-cache="true"
                            :search-input-placeholder="$t('selectOwner')"
                            :has-searchable-secondary-labels="false"
                            :search-input-min-length="3"
                            :required="false"
                            @search="onUserSearch"
                            @abort="onUserTypeAheadAbort"
                            @change="changeOwner"
                        />
                    </pui-form-group>
                </div>
                <div v-pui-form-grid-row>
                    <select-box
                        v-pui-form-grid-column="6"
                        :name="$t('deliveryTeam')"
                        :placeholder="$t('pleaseSelect')"
                        :select-box-options="deliveryTeams"
                        :input-value="selectedDeliveryTeam"
                        @change="value => selectedDeliveryTeam = value"
                    />
                </div>
                <div v-pui-form-grid-row>
                    <text-input
                        v-pui-form-grid-column="6"
                        :name="$t('useCaseModal.supportContactEmail')"
                        :placeholder="$t('useCaseModal.supportContactEmail')"
                        :input-value="useCase.supportContactEmail"
                        :isInvalid="isInvalid('contactEmailInvalid') || isInvalid('emailIsTooLong')"
                        :error="getErrorMessageForContactEmail()"
                        @change="handleContactEmail"
                    />
                </div>
                <div :class="['tags-container', 'last-row', 'inputs-row', {'input-label' : !useCase.tags.lenght}]">
                    <span class="input-label">
                        <span>{{ $t("tags") + ' ' }}</span>
                        <span v-if="isAdd" class="required">{{ $t('useCaseModal.required') }}</span>
                    </span>
                    <tags-input
                        class="mt-1"
                        :initialTags="useCase.tags"
                        :isInvalid="isInvalid('repositoryTagsRequired')"
                        :error="$t('useCaseModal.tagsAreRequired')"
                        @change="handleTagsInput"
                    />
                </div>
            </pui-collapsible>
            <pui-collapsible
                :headline="appVisibilityTitle"
                :open="false"
                class="header"
            >
                <div v-pui-form-grid-row class="mt-3">
                    <div v-pui-form-grid-column="12">
                        <pui-toggle
                            :checked="useCase.allowUsersInMultipleGroups"
                            @change="useCase.allowUsersInMultipleGroups = !useCase.allowUsersInMultipleGroups"
                        >
                            <template
                                slot="puiToggleRight"
                            >
                                <label class="toggle-label">
                                    {{ $t("allowUsersInMultipleGroups") }}
                                </label>
                            </template>
                        </pui-toggle>
                    </div>
                    <div v-pui-form-grid-column="12" class="mt-3">
                        <pui-toggle
                            :checked="useCase.external"
                            @change="useCase.external = !useCase.external"
                        >
                            <template
                                slot="puiToggleRight"
                            >
                                <label class="toggle-label">
                                    {{ $t("isExternalUseCase") }}
                                </label>
                            </template>
                        </pui-toggle>
                    </div>
                </div>
                <div v-pui-form-grid-row class="mt-3">
                    <div v-pui-form-grid-column="12">
                        <pui-toggle
                            :checked="useCase.allowExternalUsers"
                            @change="useCase.allowExternalUsers = !useCase.allowExternalUsers"
                        >
                            <template
                                slot="puiToggleRight"
                            >
                                <label class="toggle-label">
                                    {{ $t("allowExternalUsers") }}
                                </label>
                            </template>
                        </pui-toggle>
                    </div>
                </div>
                <div v-pui-form-grid-row class="mt-3">
                    <div v-pui-form-grid-column="12">
                        <pui-toggle
                            :checked="useCase.isHidden"
                            @change="useCase.isHidden = !useCase.isHidden"
                        >
                            <template
                                slot="puiToggleRight"
                            >
                                <label class="toggle-label">
                                    {{ $t("hideUseCase") }}
                                </label>
                            </template>
                        </pui-toggle>
                    </div>
                </div>
                <div v-pui-form-grid-row class="mt-3">
                    <div
                        v-pui-form-grid-column="12"
                        class="last-row"
                    >
                        <pui-toggle
                            :checked="useCase.isPublic"
                            @change="useCase.isPublic = !useCase.isPublic"
                        >
                            <template
                                slot="puiToggleRight"
                            >
                                <label class="toggle-label">
                                    {{ $t("availableForEveryone") }}
                                </label>
                            </template>
                        </pui-toggle>
                    </div>
                </div>
            </pui-collapsible>
            <pui-collapsible
                :headline="serviceNowTitle"
                :open="false"
                class="header"
            >
                <div v-pui-form-grid-row>
                    <div v-pui-form-grid-column="6">
                        <text-input
                            :name="$t('serviceNow.ownerGroup')"
                            :placeholder="$t('serviceNow.ownerGroup')"
                            :input-value="useCase.ownerGroupId"
                            :helperText="$t('useCaseModal.ownerGroupInfo')"
                            @change="value => useCase.ownerGroupId = value"
                        />
                    </div>
                    <div v-pui-form-grid-column="6">
                        <text-input
                            :name="$t('serviceNow.resolverGroup')"
                            :placeholder="$t('serviceNow.resolverGroup')"
                            :input-value="useCase.resolverGroupId"
                            :helperText="$t('useCaseModal.resolverGroupInfo')"
                            @change="value => useCase.resolverGroupId = value"
                        />
                    </div>
                </div>
                <div v-pui-form-grid-row>
                    <div v-pui-form-grid-column="6">
                        <text-input
                            :name="$t('serviceNow.businessService')"
                            :placeholder="$t('serviceNow.businessService')"
                            :input-value="useCase.businessServiceId"
                            :helperText="$t('useCaseModal.businessServiceIdInfo')"
                            @change="value => useCase.businessServiceId = value"
                        />
                    </div>
                    <div
                        v-pui-form-grid-column="6"
                        class="last-row"
                    >
                        <text-input
                            :name="$t('serviceNow.serviceOffering')"
                            :placeholder="$t('serviceNow.serviceOffering')"
                            :input-value="useCase.serviceOfferingId"
                            :helperText="$t('useCaseModal.serviceOfferingIdInfo')"
                            @change="value => useCase.serviceOfferingId = value"
                        />
                    </div>
                </div>
            </pui-collapsible>
            <pui-collapsible
                :headline="identityTitle"
                :open="false"
                class="header"
            >
                <div v-pui-form-grid-row>
                    <div v-pui-form-grid-column="6">
                        <text-input
                            :name="$t('useCaseModal.additionalPermissionUrl')"
                            :placeholder="$t('useCaseModal.additionalPermissionUrl')"
                            :input-value="useCase.additionalPermissionUrl"
                            @change="value => useCase.additionalPermissionUrl = value"
                            :characterLimit="additionalPermissionUrlMaxLength"
                        />
                    </div>
                    <div
                        v-pui-form-grid-column="6"
                        class="last-row"
                    >
                        <text-input
                            :name="$t('useCaseModal.additionalPermissionUITitle')"
                            :placeholder="$t('useCaseModal.additionalPermissionUITitle')"
                            :input-value="useCase.additionalPermissionUITitle"
                            @change="value => useCase.additionalPermissionUITitle = value"
                            :characterLimit="additionalPermissionTitleMaxLength"
                        />
                    </div>
                </div>
            </pui-collapsible>
            <pui-collapsible
                :headline="$t('useCaseModal.relatedIdeas')"
                :open="false"
                class="header"
                v-if="relatedIdeas && relatedIdeas.length"
            >
                <ul class="ideas-list">
                    <li v-for="idea in relatedIdeas"
                        :key="idea.ideaId"
                    >
                        <pui-link
                            :title="getIdeaName(idea)"
                            :href="idea.ideaLink"
                            target="_blank"
                        />
                    </li>
                </ul>
            </pui-collapsible>
        </template>
    </pui-lightbox>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import RichTextEditor from '@/components/text-editor/rich-text-editor.vue';
import TextInput from '@/components/shared/text-input.vue';
import { Category, DropdownOption, Icon, Technology, UseCaseItem, User } from '@/models';
import SelectBox from '@/components/shared/select-box.vue';
import { EventBus, USE_CASE_STATUSES, APPLICATION_TYPE } from '@/utils';
import { CategoryService, DeliveryTeamsService, TechnologyService, UserService, UseCaseService } from '@/services';
import { RelatedUseCaseService } from '@/services';
import MultiSelect from '@/components/shared/multi-select.vue';
import TagsInput from '../tags-input.vue';
import LogoSelector from '@/components/use-case/logo-selector.vue';
import './use-case-modal.less';
import { RelatedIdea } from '@/models/related-idea';

@Component({
    name: 'use-case-modal',
    components: {
        TextInput,
        SelectBox,
        RichTextEditor,
        MultiSelect,
        TagsInput,
        LogoSelector
    },
})
export default class UseCaseModal extends Vue {

    @Prop({ required: true })
    private users!: User[];

    @Prop({ required: true })
    private useCases!: UseCaseItem[];

    private categoryService: CategoryService = new CategoryService();
    private deliveryTeamService: DeliveryTeamsService = new DeliveryTeamsService();
    private technologyService: TechnologyService = new TechnologyService();
    private userService: UserService = new UserService();
    private useCaseService: UseCaseService = new UseCaseService();
    private relatedUseCaseService: RelatedUseCaseService = new RelatedUseCaseService();

    private useCase: UseCaseItem = new UseCaseItem();
    private useCaseStatuses = USE_CASE_STATUSES;
    private nameMaxLength = 60;
    private shortDescriptionMaxLength = 150;
    private descriptionMaxLength = 600;
    private additionalPermissionUrlMaxLength = 250;
    private additionalPermissionTitleMaxLength = 100;

    private categories: DropdownOption[] = [];
    private secondaryCategories: DropdownOption[] = [];
    private selectedSecondaryCategories: number[] = [];
    private deliveryTeams: DropdownOption[] = [];
    private technologies: DropdownOption[] = [];
    private selectedTechnologies: number[] = [];
    private selectedDeliveryTeam= -1;
    private usersArray: User[] = [];
    private userSuggestionList: DropdownOption[] = [];
    private selectedOwner: DropdownOption | undefined = new DropdownOption(0,'');
    private isAdd = false;
    private errorList: string[] = [];
    private isShortDescriptionFocused = false;
    private isDescriptionFocused = false;
    private relatedIdeas: RelatedIdea[] = [];

    private loadingResults = false;
    private isUrlFieldRequired = false;

    private optionalTranslatedStringKey = 'useCaseModal.optional'

    private async created(): Promise<void> {
        await this.getCategories();
        await this.getDeliveryTeams();
        await this.getTechnologies();
    }

    get isEditUseCaseMode(): boolean {
        return this.useCase.id !== undefined;
    }

    get modalName(): string {
        return this.useCase.id ? (this.$t('editUseCase') as string) : (this.$t('newUseCase') as string)
    }

    get appVisibilityTitle(): string {
        return this.$t('useCaseModal.applicationVisibility') + ' (' + this.$t(this.optionalTranslatedStringKey) + ')';
    }

    get serviceNowTitle(): string {
        return this.$t('useCaseModal.serviceNowConfiguration') + ' (' + this.$t(this.optionalTranslatedStringKey) + ')';
    }

    get identityTitle(): string {
        return this.$t('useCaseModal.identity') + ' (' + this.$t(this.optionalTranslatedStringKey) + ')';
    }

    get applicationType(): number {
        const useCaseAppType = this.useCase.applicationType;

        if (typeof(this.useCase.applicationType) === 'number') {
            return this.useCase.applicationType;
        }

        return this.applicationTypes.filter((appType: any) => appType.type?.toLowerCase() === useCaseAppType.toString().toLowerCase())[0].value;
    }

    get applicationStatus(): number {

        if(this.useCaseStatuses.filter((statusType: any) => statusType.value === Number(this.useCase.status)).length > 0)
            return Number(this.useCase.status);
        return this.useCaseStatuses.filter((statusType: any) => statusType.label.toLowerCase() === this.useCase.status.toString().toLowerCase())[0].value;
    }

    private getDeliveryTeam(): number {
        if( this.useCase.deliveryTeamId === undefined) {
            return -1;
        }
        const selectedTeam = this.deliveryTeams.filter((team: DropdownOption) => team.value === this.useCase.deliveryTeamId);
        return selectedTeam.length > 0 ? selectedTeam[0].value : -1;
    }

    private get applicationTypes():  {label: string; type: string; value: number}[] {
        return APPLICATION_TYPE.map((item: {label: string; type: string; value: number}) => ({
            label: this.$t(item.label) as string,
            type: item.type,
            value: item.value
        }))
    }

    private async getCategories(): Promise<void> {
        this.$store.commit('loading');
        try {
            const categories = (await this.categoryService.getCategories())
                .result.items;

            this.categories = categories.map((category: Category) => {
                return {
                    label: category.name,
                    value: category.id,
                };
            });
        } catch (err) {
            EventBus.$emit(
                EventBus.GLOBAL.SHOW_SNACKBAR,
                'errorLoadingCategories'
            );
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async getDeliveryTeams(): Promise<void> {
        this.$store.commit('loading');
        try {

            const deliveryTeams = (await this.deliveryTeamService.listDeliveryTeams()).result?.items;
            this.deliveryTeams = deliveryTeams?.length ? deliveryTeams?.map((deliveryTeam: any) => {
                return new DropdownOption(deliveryTeam.id, deliveryTeam.name);
            }) : [];
        } catch (err) {
            EventBus.$emit(
                EventBus.GLOBAL.SHOW_SNACKBAR,
                'errorLoadingDeliveryTeam'
            );
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async getTechnologies(): Promise<void> {
        this.$store.commit('loading');
        try {
            const technologies = (await this.technologyService.getTechnologies()).result.items;
            this.technologies = technologies.map((technolgy: Technology) => {
                return new DropdownOption(technolgy.id,technolgy.name);
            });
        } catch (err) {
            EventBus.$emit(
                EventBus.GLOBAL.SHOW_SNACKBAR,
                'errorLoadingTechnologies'
            );
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async getRelatedIdeas(useCaseId?: number): Promise<void> {
        if (!useCaseId) {
            return;
        }
        this.$store.commit('loading');
        try {
            this.relatedIdeas = await this.relatedUseCaseService.getRelatedIdeasOfUseCase(useCaseId);
        } catch (err) {
            EventBus.$emit(
                EventBus.GLOBAL.SHOW_SNACKBAR,
                'errorLoadingRelatedIdeas'
            );
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async getOwner(): Promise<void> {
        this.$store.commit('loading');
        try {
            const user = (await this.userService.getOwnerById(this.useCase.ownerId)).result;
            if (user) {
                this.selectedOwner = { label: user.name, value: user.id };
            }
        } catch (err) {
            EventBus.$emit(
                EventBus.GLOBAL.SHOW_SNACKBAR,
                'errorLoadingUsers'
            );
            throw err;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async onUserSearch(term: any): Promise<void> {
        this.$store.commit('loading');
        this.loadingResults = true;
        try {
            const result = (await this.userService.getFiltered(term)).result;
            this.usersArray = result.items;
            this.userSuggestionList = this.usersArray.map(u => { return new DropdownOption(u.id, u.name) });
        } catch (err) {
            EventBus.$emit(
                EventBus.GLOBAL.SHOW_SNACKBAR,
                'errorLoadingUsers'
            );
            throw err;
        } finally {
            this.$store.commit('loading');
            this.loadingResults = false;
        }
    }

    private onUserTypeAheadAbort(): void {
        this.loadingResults = false
    }

    private handleNameInput(value: string): void {
        this.useCase.name = value;
        if(!this.isEditUseCaseMode)
            this.useCase.identityClientId = this.generatedClientId();
        this.deleteInputFieldError('repositoryNameRequired');
    }

    private generatedClientId(): string {
        if(this.useCase.name)
            return 'coode-' + this.useCase.name.trim().toLowerCase().replaceAll(' ','-');
        return '';
    }

    private handleClientIdInput(value: string): void {
        this.useCase.identityClientId = value;
        this.deleteInputFieldError('identityClientIdRequired');
    }

    private handleShortDescriptionInput(value: string): void {
        this.useCase.shortDescription = value;
        this.deleteInputFieldError('repositoryShortDescriptionRequired')
    }

    private handleDescriptionInput(value: any): void{
        this.useCase.description = value;
        this.deleteInputFieldError('repositoryDescriptionRequired')
    }

    private handleURLInput(value: string): void{
        this.useCase.url = value;
        this.deleteInputFieldError('repositoryURLRequired')
    }

    private handleChangeMainCateg(value: number): void {
        this.useCase.mainCategoryId = value;
        this.deleteInputFieldError('mainCategoryRequired');
        this.secondaryCategories = this.categories.filter((categ: DropdownOption) => categ.value !== value);

        if (this.selectedSecondaryCategories.includes(value)) {
            const index = this.selectedSecondaryCategories.indexOf(value);
            this.selectedSecondaryCategories.splice(index, 1);
        }
    }

    private handleChangeTechnologies(value: any): void {
        this.selectedTechnologies = value
        this.deleteInputFieldError('repositoryTechnologiesRequired');
    }

    private handleTagsInput(value: string[]): void {
        this.useCase.tags = value;
        this.deleteInputFieldError('repositoryTagsRequired');
    }

    private handleIconChange(icon: Icon): void {
        this.useCase.logoId = icon.id;
        this.useCase.logoName = icon.name;
        this.useCase.logoUrl = icon.downloadPath;
    }

    private handleContactEmail(value: string): void {
        this.useCase.supportContactEmail = value.length ? value : undefined;
        this.deleteInputFieldError('contactEmailInvalid');
        this.deleteInputFieldError('emailIsTooLong');
    }

    private getErrorMessageForContactEmail(): string {
        if (this.isInvalid('contactEmailInvalid'))
            return this.$t('useCaseModal.emailIsInvalid') as string;
        if(this.isInvalid('emailIsTooLong'))
            return this.$t('useCaseModal.emailIsTooLong') as string;
        return '';
    }

    private changeOwner(owner: any): void {
        if(owner.length) {
            const user = this.usersArray.find(u => u.id === (owner[0].value || owner[0]));
            if (user) {
                this.useCase.ownerId = user.id;
            }
            this.userSuggestionList = this.users.map(u => { return { label: u.name, value: u.id }});
        }
    }

    private async saveUseCase(): Promise<void> {
        this.checkInput();
        if (this.errorList.length > 0) {
            return;
        }
        this.useCase.categories = this.selectedSecondaryCategories.filter((categoryId) => categoryId !== this.useCase.mainCategoryId);
        this.useCase.technologies = this.selectedTechnologies;
        this.useCase.deliveryTeamId = this.selectedDeliveryTeam === -1 ? undefined : this.selectedDeliveryTeam;
        this.$store.commit('loading');
        this.close()
        try {
            if (this.useCase.id > 0) {
                await this.useCaseService.update(this.useCase.id, this.useCase);
                this.$emit('save');
            } else {
                await this.useCaseService.add(this.useCase);
                this.$emit('save');
            }
        } catch (error) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorSavingUseCase');
        } finally {
            this.$store.commit('loading');
        }
    }

    public async open(useCase: any, isAdd: boolean): Promise<void> {
        await this.getRelatedIdeas(useCase?.id);
        this.errorList = [];
        this.isAdd = isAdd;
        this.useCase = useCase;
        this.secondaryCategories = this.categories.filter((categ: any) => (typeof categ !== 'number') && categ.value !== this.useCase.mainCategoryId);
        this.selectedSecondaryCategories = ((this.useCase?.categories ?? []) as Category[]).map((category: Category) => category.id);
        this.selectedTechnologies = ((this.useCase.technologies ?? []) as Technology[]).map((technology: Technology) => technology.id);
        this.selectedOwner = new DropdownOption(0,'');
        this.selectedDeliveryTeam = this.getDeliveryTeam();
        if(!this.isAdd && this.useCase.ownerId) {
            await this.getOwner();
        }
        (this.$refs.useCaseModal as any)?.open();
    }

    public close(): void {
        (this.$refs.useCaseModal as any)?.close();
    }

    private checkName(): void {
        if (!this.useCase.name || this.useCase.name.trim().length === 0) {
            this.errorList.push('repositoryNameRequired');
        }
    }

    private checkURL(): void {
        if (!this.isUrlFieldRequired) return;

        if (!this.useCase.url || this.useCase.url.trim().length === 0) {
            this.errorList.push('repositoryURLRequired');
        }
    }

    private checkIdentityClient(): void {
        if (!this.useCase.identityClientId) {
            this.errorList.push('identityClientIdRequired');
        }
    }

    private hasRichTextEditorEmptyField(value: string): boolean {
        return value === '<p></p>' || !value
            || value.trim().length === 0;
    }

    private checkDescription(): void {
        if (this.hasRichTextEditorEmptyField(this.useCase.description))
            this.errorList.push('repositoryDescriptionRequired');
    }

    private checkShortDescription(): void {
        if (this.hasRichTextEditorEmptyField(this.useCase.shortDescription))
            this.errorList.push('repositoryShortDescriptionRequired');
    }

    private checkMainCategory(): void {
        if (!this.useCase.mainCategoryId) {
            this.errorList.push('mainCategoryRequired');
        }
    }

    private checkTechnologies(): void {
        if (!this.selectedTechnologies || this.selectedTechnologies.length == 0) {
            this.errorList.push('repositoryTechnologiesRequired');
        }
    }

    private checkTags(): void {
        if (!this.useCase.tags || !this.useCase.tags.length) {
            this.errorList.push('repositoryTagsRequired');
        }
        if(this.useCase.tags && this.useCase.tags.every((tag: string) => tag.trim().length === 0)) {
            this.errorList.push('repositoryTagsRequired');
        }
    }

    private checkEmail(): void {
        const emailValidator = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const email = this.useCase.supportContactEmail;
        if(email) {
            const isEmailValid = emailValidator.test(String(email).toLowerCase());
            const isEmailLengthCorrect = email.length <= 50;
            if(!isEmailValid) {
                this.errorList.push('contactEmailInvalid');
            }
            if (!isEmailLengthCorrect) {
                this.errorList.push('emailIsTooLong');
            }
        }
    }

    private isInvalid(invalidKey: string): boolean {
        return this.errorList.indexOf(invalidKey) > -1;
    }

    private checkInput(): void {
        this.errorList = [];
        this.checkName();
        this.checkURL();
        this.checkIdentityClient();
        this.checkDescription();
        this.checkShortDescription();
        this.checkMainCategory();
        this.checkTechnologies();
        this.checkTags();
        this.checkEmail();

        for (const useCase of this.useCases) {
            if (
                useCase.identityClientId === this.useCase.identityClientId &&
                useCase.id !== this.useCase.id
            ) {
                this.errorList.push('identityClientIdIsUnique');
                break;
            }
        }
    }

    private deleteInputFieldError(invalidKey: string): any {
        const errorIndex = this.errorList.indexOf(invalidKey);
        if (errorIndex > -1) {
            this.errorList.splice(errorIndex, 1);
        }
    }

    private setShortDescriptionInputOutline(value: boolean): void {
        this.isShortDescriptionFocused = value;
    }

    private setDescriptionInputOutline(value: boolean): void {
        this.isDescriptionFocused = value;
    }

    private getIdeaName(idea: RelatedIdea): string {
        return idea?.coodeId + ' ' + idea?.ideaName;
    }
}
</script>
