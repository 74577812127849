import { Icon } from '@/models';
import store from '@/store';

const DEFAULT_LOGO = 'app-generic';
export default class IconHelper {

    public static getDefaultAppIcon(): Icon {
        return store.getters.icons.filter((icon: Icon) => icon.name === DEFAULT_LOGO)[0];
    }

    public static getIconName(iconId: number): string {
        return iconId ? store.getters.icons.filter((icon: Icon) => icon.id === iconId)[0]?.name : DEFAULT_LOGO;
    }

    public static getIconId(iconName: string): string {
        return store.getters.icons.filter((icon: Icon) => icon.name === iconName)[0]?.id;
    }

    public static getIconImagePath(iconId: number): string {
        return store.getters.icons.filter((icon: Icon) => icon.id === iconId)[0]?.downloadPath;
    }
}
