<template>
    <div v-if="editor" class="editor">
        <editor-toolbar
            :class="['editor_header flex-row justify-between', {invalid: isInvalid}]"
            :editor="editor"
            :characterLimit="characterLimit"
        />
        <editor-content :class="['editor-content', {invalid: isInvalid}]" :editor="editor" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import EditorToolbar from './editor-toolbar.vue'
import CharacterCount from '@tiptap/extension-character-count';
import Placeholder from '@tiptap/extension-placeholder';

@Component({
    components: {
        EditorContent,
        EditorToolbar
    },
})
export default class RichTextEditor extends Vue {
    private editor: any = null;

    @Prop({ required: true })
    value!: any;

    @Prop({ required: false })
    characterLimit!: number;

    @Prop({ required: false })
    placeholder!: string;

    @Prop({ required: false })
    isInvalid!: boolean;

    @Watch('value')
    textEditorValueChanged(): void {
        if(this.editor.getHTML() === this.value)
            return;
        this.editor.commands.setContent(this.value, false);
    }

    private mounted(): void {
        this.editor = new Editor({
            content: this.value,
            extensions: [
                StarterKit,
                CharacterCount.configure({
                    limit: this.characterLimit,
                }),
                Placeholder.configure({
                    placeholder: this.placeholder,
                }),],
            onUpdate: (): void => {
                this.$emit('input', this.editor.getHTML());
            },
            onFocus: (): void => {
                this.$emit('focus');
            },
            onBlur: (): void => {
                this.$emit('blur');
            },
        });
    }

    private beforeDestroy(): void {
        this.editor.destroy();
    }
}
</script>

<style lang="less">
@import '~@/variables.less';

.editor {
  display: flex;
  flex-direction: column;
  max-height: 34rem;
  color: #0d0d0d;
  background-color: #fff;

  &_header {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 1.25rem;
    border-bottom: solid 1px @warm-grey;

    &.invalid {
        background-color: @pink-lighter;
    }
  }

  &-content {
    min-height: 16rem;
    max-height: 20rem;
    overflow-y: auto;

    &.invalid {
        background-color: @pink-lighter;
    }
  }

  p {
        padding-left: 1.3rem;
        strong {
            color: black;
            font-weight: bold;
        }
    }

    ul li {
        list-style-type: unset;
        margin-left: 3rem;
        line-height: 1.5rem;
    }

    .ProseMirror {
        font-family: 'Roboto', 'Noto Sans', Arial, -apple-system, BlinkMacSystemFont, sans-serif;
        cursor: text;
        &:focus {
            outline: none;
        }

        p.is-editor-empty:first-child::before{
            content: attr(data-placeholder);
            float: left;
            color: #757575;
            pointer-events: none;
            height: 0;
            font-size: 1.4rem;
        }
    }
}
</style>
