export const USE_CASE_STATUSES = [
    {
        label: 'none',
        value: 0,
    },
    {
        label: 'new',
        value: 1,
    },
    {
        label: 'updated',
        value: 2,
    },
    {
        label: 'soon',
        value: 3,
    },
    {
        label: 'live',
        value: 4,
    },
    {
        label: 'notShownInUi',
        value: 5,
    },
];
