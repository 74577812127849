<template>
    <div class="flex-col dropdown-with-label">
        <span class="input-label">{{ $t("logo") + ' ' }}</span>

        <div
            class="trigger-container mt-1"
            @click="handleDropdownClick"
        >
            <div class="flex-row flex-center input-and-icon">
                <img
                    class="logo"
                    :src="inputImage"
                >
                <input
                    class="dropdown-input"
                    type="text"
                    :placeholder="$t('pleaseSelect')"
                    :value="inputValue"
                    @input="handleTypeInput"
                >
                <pui-icon
                    :class="[{iconUp: isOpen}]"
                    icon-color="black"
                    icon-name="small-down"
                    size="24"
                />
            </div>
        </div>
        <div
            v-if="isOpen"
            class="dropdown-overlay"
        >
            <ul class="mt-0 mb-0" v-if="suggestedOptions.length">
                <li
                    class="dropdown-option-wrapper"
                    @click="selectImage"
                >
                    <div class="dropdown-option">
                        <pui-icon
                            class="add-icon logo"
                            icon-name="add"
                            size="16"
                        />
                        <span class="dropdown-option-label">
                            {{ $t("useCaseModal.addNewLogo") }}
                        </span>
                    </div>
                </li>

                <li
                    :class="['dropdown-option-wrapper',{selected : option.selected}]"
                    v-for="option in suggestedOptions"
                    :key="option.path"
                    @click="handleChangeInputValue(option)"
                >
                    <div class="dropdown-option">
                        <img
                            class="logo"
                            :src="option.path"
                        >
                        <span class="dropdown-option-label">
                            {{ option.name }}
                        </span>
                    </div>
                </li>
            </ul>
            <div v-else class="no-results">
                {{ $t("noResultsFound") }}
            </div>
        </div>
        <div class="info flex-row flex-center">
            <pui-icon
                class="info-icon"
                icon-name="info"
                size="16"
            />
            <span>{{ $t("useCaseModal.uploadedImageFileTypeShouldBeSvg") + ' ' }}</span>
        </div>
        <form enctype="multipart/form-data">
            <input
                class="hidden-file-input"
                ref="fileInput"
                type="file"
                accept=".svg"
                @input="chooseFile"
            >
        </form>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop} from 'vue-property-decorator';
import { Icon, IconOption } from '@/models';
import IconHelper from '@/utils/icon-helper';
import { iconService } from '@/services';

@Component({
    name: 'logo-selector',
    components: {},
})

export default class LogoSelector extends Vue {

    @Prop({ required: true })
    private selectedLogoId!: number;

    @Prop({ required: true })
    private selectedLogoName!: string;

    @Prop({ required: true })
    private selectedLogoUrl!: string;

    private isOpen = false;
    private isUploadingIcon = false;
    private inputValue = this.selectedLogoName;
    private inputImage = this.selectedLogoUrl;
    private options: IconOption[] = [];
    private suggestedOptions: any[] = [];
    private DEFAULT_LOGO = 'app-generic';

    private fileToUpload: any = {
        data: {},
        fileName: ''
    };
    private source = '';

    private created(): void {
        this.options = this.appIcons.map((icon: Icon) => new IconOption(icon));
        this.suggestedOptions = this.options;
        this.markAsSelected(this.selectedLogoName);
    }

    private mounted(): void {
        document.addEventListener('click', this.close);
        if(!this.logoIsValid()) {
            this.inputImage = IconHelper.getDefaultAppIcon().downloadPath;
            this.inputValue = this.DEFAULT_LOGO;
            this.markAsSelected(this.DEFAULT_LOGO);
        }
    }

    private handleDropdownClick(): void {
        this.isOpen = !this.isOpen;
        if(this.isOpen)
            this.inputValue = '';
        else {
            if (!this.isUploadingIcon) {
                this.inputValue = this.selectedLogoName;
                this.inputImage = this.selectedLogoUrl;
            }
            if (this.isUploadingIcon && !this.selectedLogoId) {
                this.inputValue = this.getUploadedLogoName();
                this.inputImage = this.getUploadedLogoImagePath();
            }
        }

        if(!this.logoIsValid() && !this.isUploadingIcon ) {
            this.inputImage = IconHelper.getDefaultAppIcon().downloadPath;
        }
    }

    private handleChangeInputValue(option: IconOption): void {
        this.inputValue = option.name;
        this.inputImage = option.path;
        this.deselectAllOptions();
        this.markAsSelected(option.name);
        this.isOpen = false;
        this.$emit('change', new Icon(
            {
                id: option.id,
                name: option.name,
                downloadPath: option.path
            }
        ));
    }

    private handleTypeInput(e: any): void {
        const searchTerm = e.target.value;
        this.inputValue = searchTerm;
        this.suggestedOptions = this.options.filter((option: any) => option.name.includes(searchTerm.trim()));
    }

    private markAsSelected(optionName: string): void {
        this.suggestedOptions.filter((app: any) => app.name === optionName)[0].selected = true;
    }

    private deselectAllOptions(): void {
        this.suggestedOptions = this.suggestedOptions.map((icon: Icon) => { return { ...icon, selected: false }});
    }

    private selectImage(): void {
        (this.$refs.fileInput as any).click();
    }

    private fileIsValid(file: File): boolean {
        const exp = new RegExp('[^\\s]+(.*?)\\.(svg|SVG)$');
        if(file)
            return exp.test(file.name) && file.type === 'image/svg+xml';
        return false;
    }

    private async chooseFile(): Promise<void> {
        const fileInput = this.$refs.fileInput
        const file = (fileInput as any).files[0];

        this.fileToUpload.fileName = file.name;
        this.fileToUpload.data = file;
        this.source = URL.createObjectURL(file);

        if(this.fileIsValid(file)) {
            this.isUploadingIcon = true;
            const successUpload = await iconService.uploadIcon(this.fileToUpload);

            if (successUpload) {
                this.inputValue = this.getUploadedLogoName();
                this.inputImage = this.source;
                this.deselectAllOptions();
                this.suggestedOptions.unshift({name: this.inputValue, selected: true, path: this.source});
                await this.$store.dispatch('loadIcons');
                this.markAsSelected(this.inputValue);
                this.isOpen = false;
                this.$emit('change', new Icon(
                    {
                        id: IconHelper.getIconId(this.inputValue),
                        name: this.inputValue,
                        downloadPath: this.inputImage
                    }
                ));
            }
        }
    }

    private getUploadedLogoName(): string {
        //remove extension from file name (.svg)
        return this.fileToUpload.fileName.slice(0, -4);
    }

    private getUploadedLogoImagePath(): string {
        return URL.createObjectURL(this.fileToUpload.data);
    }

    private logoIsValid(): boolean {
        return this.appIcons.filter((icon: Icon) => icon.id === this.selectedLogoId).length > 0;
    }

    private get appIcons(): Icon[] {
        return this.$store.getters.icons;
    }

    private close(e: any): void {
        if (!this.$el.contains(e.target)) {
            this.isOpen = false;
            if (this.selectedLogoName)
                this.inputValue = this.selectedLogoName;
        }
    }

    private beforeDestroy(): void {
        document.removeEventListener('click',this.close);
    }
}
</script>

<style lang="less" scoped>
@import '../../variables.less';
.dropdown-with-label {
    position: relative;
    padding-bottom: 0.4rem;
    .input-and-icon {
        width: 100%;
        input {
            border: none;
            height: 100%;
        }
        .iconUp {
            transform: rotate(180deg);
            transition: transform 0.2s ease-in-out;
        }
    }
    .no-results {
        height: 4rem;
        padding: 0 1.6rem;
        font-size: 1.4rem;
        line-height: 2rem;
        color: @dark-grey;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dropdown {
        &-overlay{
            max-height: 19rem;
            background-color: white;
            margin-top: 10rem;
            overflow: auto;
            box-shadow: 0 0 8px 0 #adadad;
            border-radius: 0.2rem;
            z-index: 1;
            position: absolute;
            width: 100%;

            .add-icon {
                height: auto;
                color: @uniper-blue;
            }
        }

        &-option-wrapper {
            padding: 0.4rem 1.6rem;
            cursor: pointer;
            line-height: 1;
            &:hover {
                background-color: rgba(0,120,220,0.15);
            }
            &.selected {
                background-color: rgba(0,120,220,0.25);
                font-weight: 500;
            }
        }

        &-option {
            min-height: 4.5rem;
            display: flex;
            align-items: center;

            &-label {
                font-size: 1.4rem;
                color: @dark-grey;
                font-weight: 500;
            }
        }
    }
    .trigger-container {
        display: flex;
        align-items: center;
        border-radius: 0.4rem;
        min-height: 3.8rem;
        height: 3.8rem;
        background-color: @white;
        border: 1px solid @warm-grey;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        user-select: none;
        font-size: 1.6rem;
        padding-left: 1.2rem;
        padding-right: 1.4rem;
        &:focus-visible {
            outline: none;
        }
        &:focus {
            border: 1px solid @uniper-blue;
        }
        &:hover {
            border: 1px solid @uniper-blue;
            cursor: pointer;
        }

        .dropdown-input{
            cursor: pointer;
            width: 100%;
            color: @dark-grey;
            &:focus-visible {
                outline: none;
            }
            &::placeholder {
                color: @warm-grey;
                opacity: 1;
            }
        }
    }
    .input-label {
        padding: 2.4rem 1.2rem 0.4rem 1.2rem;
        font-size: 1.4rem;
    }

    .info {
        color: @warm-grey;
        padding: 0.4rem 1.2rem 0 1.2rem;
        font-size: 1.2rem;
        line-height: 1.33;
        &-icon {
            color: @warm-grey;
            margin-right: 0.5rem;
        }
    }

    .logo {
        width: 2.5rem;
        margin-right: 1rem;
    }

    .hidden-file-input {
        display: none;
    }
}
</style>
