import { ApiResponse, ApiResponseList, Application } from '@/models';
import { sdk } from '@/utils/sdk';

/**
 * @name AppService
 * @description This class is used to query the identity server for user related data,
 * which is not related to authentication.
 */
export class AppService {

    public async getApps(): Promise<ApiResponse<ApiResponseList<Application>>> {
        return (await sdk?.core.metaData.request.api.get<ApiResponse<ApiResponseList<Application>>>('use-cases')).data;
    }
}
