import Vue from 'vue';
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

const lang = 'en-GB';

export const i18n = new VueI18n({
    locale: lang,
    messages: require(`./locales/${lang}.json`),
})
