<template>
    <div>
        <div class="flex-row justify-between">
            <template v-for="(item, index) in tools">
                <div
                    class="divider"
                    v-if="item.type === 'divider'"
                    :key="`divider${index}`"
                />
                <editor-tool v-else :key="index" v-bind="item" />
            </template>
        </div>
        <span class="char-counter">{{ characterCount }}/{{ characterLimit }}</span>
    </div>
</template>

<script lang="ts">
import EditorTool from './editor-tool.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        EditorTool,
    },
})
export default class EditorToolbar extends Vue {
  @Prop({ required: true })
  editor!: any;

  @Prop({ required: false })
  characterLimit!: number;

  get characterCount(): number {
      return this.editor.storage.characterCount.characters();
  }

  private tools = [
      {
          icon: 'bold',
          title: 'Bold',
          action: (): void => this.editor.chain().focus().toggleBold().run(),
          isActive: (): any => this.editor.isActive('bold'),
      },
      {
          icon: 'italic',
          title: 'Italic',
          action: (): void => this.editor.chain().focus().toggleItalic().run(),
          isActive: (): any => this.editor.isActive('italic'),
      },
      {
          icon: 'strikethrough',
          title: 'Strike',
          action: (): void => this.editor.chain().focus().toggleStrike().run(),
          isActive: (): any => this.editor.isActive('strike'),
      },
      {
          type: 'divider',
      },
      {
          icon: 'heading',
          title: 'Heading 1',
          action: (): void =>
              this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: (): any => this.editor.isActive('heading', { level: 1 }),
      },
      {
          icon: 'heading',
          title: 'Heading 2',
          action: (): void =>
              this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: (): any => this.editor.isActive('heading', { level: 2 }),
      },
      {
          icon: 'list-ul',
          title: 'Bullet List',
          action: (): void => this.editor.chain().focus().toggleBulletList().run(),
          isActive: (): any => this.editor.isActive('bulletList'),
      },
      {
          icon: 'list-ol',
          title: 'Ordered List',
          action: (): void => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: (): any => this.editor.isActive('orderedList'),
      },
      {
          type: 'divider',
      },
      {
          icon: 'grip-lines',
          title: 'Horizontal Rule',
          action: (): void => this.editor.chain().focus().setHorizontalRule().run(),
          isActive: false
      },
      {
          icon: 'paragraph',
          title: 'Hard Break',
          action: (): void => this.editor.chain().focus().setHardBreak().run(),
          isActive: false
      },
      {
          icon: 'remove-format',
          title: 'Clear Format',
          action: (): void => this.editor.chain().focus().clearNodes().unsetAllMarks().run(),
          isActive: false
      },
      {
          type: 'divider',
      },
      {
          icon: 'undo',
          title: 'Undo',
          action: (): void => this.editor.chain().focus().undo().run(),
          isActive: false
      },
      {
          icon: 'redo',
          title: 'Redo',
          action: (): void => this.editor.chain().focus().redo().run(),
          isActive: false
      },
  ];
}
</script>

<style lang="less">
@import '../../variables.less';
.divider {
  width: 2px;
  height: 1.25rem;
  background-color: rgba(#000, 0.1);
  margin-left: 0.5rem;
  margin-right: 0.75rem;
}
.char-counter {
    font-size: 1.3rem;
    color: @warm-grey;
}
</style>
