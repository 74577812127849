import { Technology } from '.';
import { Category } from './category';

export class UseCaseItem {
    id: number;
    name: string;
    shortDescription: string;
    description: string;
    identityClientId: string;
    allowUsersInMultipleGroups: boolean;
    allowExternalUsers: boolean;
    url: string;
    logo: string;
    logoId: number;
    logoUrl: string;
    logoName: string;
    status: number;
    external: boolean;
    ownerId: number;
    tags: string[];
    isHidden: boolean;
    mainCategoryId: number | null;
    categories: Category[] | number[] | null;
    technologies: Technology[] | number[] | null;
    isPublic: boolean;
    deliveryTeamId?: number | undefined;
    applicationType: string | number;
    ownerGroupId?: string;
    resolverGroupId?: string;
    businessServiceId?: string;
    serviceOfferingId?: string;
    additionalPermissionUrl?: string;
    additionalPermissionUITitle?: string;
    supportContactEmail?: string;

    public constructor(data?: any) {
        this.id = data?.id;
        this.name = data?.name;
        this.shortDescription = data?.shortDescription;
        this.description = data?.description;
        this.identityClientId = data?.identityClientId;
        this.allowUsersInMultipleGroups = data?.allowUsersInMultipleGroups;
        this.allowExternalUsers = data?.allowExternalUsers;
        this.url = data?.url;
        this.logo = data?.logo ?? 'app-generic';
        this.logoId = data?.logoId;
        this.logoUrl = data?.logoUrl ?? '';
        this.logoName = data?.logoName ?? 'app-generic';
        this.status = data?.status ?? 0;
        this.external = data?.external;
        this.ownerId = data?.ownerId;
        this.tags = data?.tags ?? [''];
        this.isHidden = data?.isHidden;
        this.mainCategoryId = data?.mainCategoryId;
        this.categories = data?.categories ?? [];
        this.technologies = data?.technologies ?? [];
        this.isPublic = data?.isPublic;
        this.deliveryTeamId = data?.deliveryTeamId;
        this.applicationType = data?.applicationType ?? 1;
        this.ownerGroupId = data?.ownerGroupId;
        this.resolverGroupId = data?.resolverGroupId;
        this.businessServiceId = data?.businessServiceId;
        this.serviceOfferingId = data?.serviceOfferingId;
        this.additionalPermissionUrl = data?.additionalPermissionUrl;
        this.additionalPermissionUITitle = data?.additionalPermissionUITitle;
        this.supportContactEmail = data?.supportContactEmail;
    }
}
