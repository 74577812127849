import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'adam.ui-core/dist/umd';
import 'adam.ui-core/utils/scaling.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCopy, faCog, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './registerServiceWorker';
import { VNode } from 'vue/types/umd';
import PebbleUI from '@enerlytics/pebble-ui/dist/pebble-ui';
import { i18n } from './i18n-instance-file';
import { sdk } from '@/utils/sdk';

Vue.use(PebbleUI, {
    dateTimeLocale: 'en-US',
    dateTimeTimeZone: 'Europe/London',
});

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
library.add(faCopy as any , faCog as any , faTrash as any , faPlus as any );

Vue.config.productionTip = false;

sdk?.auth.init().then(() => {
    new Vue({
        i18n,
        router,
        store,
        render: (h): VNode => h(App),
    }).$mount('#app');
})
