import { Icon } from './icon';

export class IconOption {
    id: number;
    name: string;
    selected?: boolean;
    path: string;

    public constructor(data: any | Icon) {
        this.id = data?.id;
        this.name = data?.name;
        this.selected = data?.selected ?? false;
        this.path = data?.downloadPath ?? '';
    }
}
