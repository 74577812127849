<template>
    <div v-if="isDataLoaded" class="use-case">
        <div class="flex-row justify-between mb-2">
            <search-bar
                @filter-by-keyword="handleFilterByKeyword"
            />
            <pui-button
                state="primary"
                icon="add"
                @click="addUseCase()"
            >
                {{ $t("addNew") }}
            </pui-button>
        </div>
        <pui-table
            :vendor-options="tableConfig"
            class="mb-2"
        >
            <template
                :slot="tableColumnHeaders.id"
                slot-scope="{ row }"
            >
                <div class="flex-row flex-center">
                    <img
                        class="logo"
                        :src="row.logoUrl"
                        alt=""
                    >
                    <span>{{ row.id }}</span>
                </div>
            </template>
            <template
                :slot="tableColumnHeaders.name"
                slot-scope="{ row }"
            >
                {{ row.name }}
            </template>
            <template
                :slot="tableColumnHeaders.identityClientId"
                slot-scope="{ row }"
            >
                {{ row.identityClientId }}
            </template>
            <template
                :slot="tableColumnHeaders.allowUsersInMultipleGroups"
                slot-scope="{ row }"
            >
                <span :class="[row.allowUsersInMultipleGroups? 'font-color-green': 'font-color-red']">
                    {{ row.allowUsersInMultipleGroups ? "Allow" : "Deny" }}
                </span>
            </template>
            <template
                :slot="tableColumnHeaders.actions"
                slot-scope="{ row }"
            >
                <div class="flex-row space-between">
                    <pui-button
                        state="secondary"
                        small
                        icon="edit"
                        @click="openUseCaseModal(row)"
                    >
                        {{ $t("edit") }}
                    </pui-button>
                    <pui-button
                        state="secondary"
                        small
                        icon="delete"
                        @click="openConfirmationModal(row)"
                    >
                        {{ $t("delete") }}
                    </pui-button>
                </div>
            </template>
        </pui-table>
        <use-case-modal
            ref="useCaseDetails"
            :users="users"
            :useCases="useCases"
            @save="useCaseModalSave"
        />
        <confirm-modal
            ref="delete-confirmation-dialog"
            @confirm="removeUseCase"
        />
    </div>
    <div v-else />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import UseCaseModal from './use-case-modal/use-case-modal.vue';
import ConfirmModal from '@/components/confirm-modal/confirm-modal.vue';
import { Icon, UseCaseItem } from '@/models';
import { UseCaseService, UserService } from '@/services';
import { EventBus } from '@/utils';
import { User } from '@/models';
import SearchBar from '@/components/shared/search-bar.vue';

@Component({
    name: 'use-case',
    components: {
        UseCaseModal,
        ConfirmModal,
        SearchBar
    },
})
export default class UseCase extends Vue {
    private useCases: UseCaseItem[] = [];
    private filteredUseCases: UseCaseItem[] = [];
    private useCaseToEdit: UseCaseItem | null = null;
    private useCaseToDelete: UseCaseItem | null = null;
    private users: User[] = [];
    private useCaseService: UseCaseService = new UseCaseService();
    private userService: UserService = new UserService();
    private isDataLoaded = false;

    private async mounted(): Promise<void> {
        await this.init();
    }

    private openUseCaseModal(useCase: UseCaseItem): void {
        (this.$refs.useCaseDetails as UseCaseModal).open(new UseCaseItem(useCase), false);
    }

    private openConfirmationModal(useCase: UseCaseItem): void {
        this.useCaseToDelete = useCase;
        (this.$refs['delete-confirmation-dialog'] as ConfirmModal).open(this.$t('areYouSureToRemoveUseCase') + ' ' + useCase.name + '?');
    }

    private addUseCase(): void {
        (this.$refs.useCaseDetails as UseCaseModal).open(new UseCaseItem(), true);
    }

    private async init(): Promise<void> {
        this.$store.commit('loading');
        try {
            this.useCases = (await this.useCaseService.get()).result.items;
            this.filteredUseCases = this.useCases;
            const result = (await this.userService.getAll()).result;
            this.users = result.items;
            await this.$store.dispatch('loadIcons');
            this.isDataLoaded = true;
        } catch (error) {
            this.isDataLoaded = false;
            EventBus.$emit(
                EventBus.GLOBAL.SHOW_SNACKBAR,
                'errorLoadingUseCases'
            );
            throw error;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async executeRemoveUseCase(useCase: UseCaseItem | null): Promise<void> {
        this.$store.commit('loading');
        try {
            if (useCase && useCase.id > 0) {
                await this.useCaseService.delete(useCase.id);
                this.useCases = this.useCases.filter(
                    (at) => at.id !== useCase.id
                );
            }
        } catch (error) {
            EventBus.$emit(
                EventBus.GLOBAL.SHOW_SNACKBAR,
                'errorDeletingUseCase'
            );
            throw error;
        } finally {
            this.$store.commit('loading');
        }
    }

    private async removeUseCase(): Promise<void> {
        await this.executeRemoveUseCase(this.useCaseToDelete);
        this.useCases = (await this.useCaseService.get()).result.items;
        this.filteredUseCases = this.useCases;
    }


    private async useCaseModalSave(): Promise<void> {
        await this.init();
        (this.$refs.useCaseDetails as UseCaseModal).close();
    }

    get tableColumnHeaders(): {id: string; name: string; identityClientId: string; allowUsersInMultipleGroups: string; actions: string} {
        return {
            id: this.$t('id') as string,
            name: this.$t('name') as string,
            identityClientId: this.$t('identityClientId') as string,
            allowUsersInMultipleGroups: this.$t('allowUsersInMultipleGroups') as string,
            actions: this.$t('actions') as string
        }
    }

    get tableConfig(): any {
        return {
            options: {
                sortable: [
                    this.tableColumnHeaders.id
                ],
                perPage: this.useCases.length
            },
            columns: Object.values(this.tableColumnHeaders),
            data: this.filteredUseCases,
        };
    }

    private handleFilterByKeyword(searchTerm: string): void {
        this.filteredUseCases = this.useCases.filter(item => this.useCaseMatchesSearchTerm(searchTerm, item));
    }

    private useCaseMatchesSearchTerm(searchTerm: string, useCase: UseCaseItem): boolean {
        if (!searchTerm.length) {
            return true;
        }

        const {name, id, identityClientId} = useCase;
        return [name, id.toString(), identityClientId]
            .filter(value => value)
            .map(value => value?.toLowerCase().replace(/^\s+|\s+$/gm,''))
            .some(value => value?.includes(searchTerm.toLowerCase().replace(/^\s+|\s+$/gm,'')));
    }

    private get appIcons(): Icon[] {
        return this.$store.getters.icons;
    }
}
</script>

<style lang="less" src='./use-case.less' />
