import { ApiResponse } from '@/models';
import { sdk } from '@/utils/sdk';

export class UserService {

    private endpoint = 'users';

    public async getAll(): Promise<ApiResponse<any>> {
        return (await sdk?.core.userAccessManagement.request
            .api.get<ApiResponse<any>>(`${this.endpoint}?page=1&size=1000`)).data;
    }

    public async getFiltered(term: any): Promise<ApiResponse<any>> {
        return (await sdk?.core.userAccessManagement.request
            .api.get<ApiResponse<any>>(`${this.endpoint}?page=1&size=300&term=${term}&userGroupId=&sortColumn=1&sortDirection=Ascending`)).data;
    }

    public async getOwnerById(id: number): Promise<ApiResponse<any>> {
        return (await sdk?.core.userAccessManagement.request
            .api.get<ApiResponse<any>>(`${this.endpoint}/${id}`)).data;
    }
}
