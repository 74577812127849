var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isDataLoaded)?_c('div',{staticClass:"use-case"},[_c('div',{staticClass:"flex-row justify-between mb-2"},[_c('search-bar',{on:{"filter-by-keyword":_vm.handleFilterByKeyword}}),_c('pui-button',{attrs:{"state":"primary","icon":"add"},on:{"click":function($event){return _vm.addUseCase()}}},[_vm._v(" "+_vm._s(_vm.$t("addNew"))+" ")])],1),_c('pui-table',{staticClass:"mb-2",attrs:{"vendor-options":_vm.tableConfig},scopedSlots:_vm._u([{key:_vm.tableColumnHeaders.id,fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-row flex-center"},[_c('img',{staticClass:"logo",attrs:{"src":row.logoUrl,"alt":""}}),_c('span',[_vm._v(_vm._s(row.id))])])]}},{key:_vm.tableColumnHeaders.name,fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}},{key:_vm.tableColumnHeaders.identityClientId,fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.identityClientId)+" ")]}},{key:_vm.tableColumnHeaders.allowUsersInMultipleGroups,fn:function(ref){
var row = ref.row;
return [_c('span',{class:[row.allowUsersInMultipleGroups? 'font-color-green': 'font-color-red']},[_vm._v(" "+_vm._s(row.allowUsersInMultipleGroups ? "Allow" : "Deny")+" ")])]}},{key:_vm.tableColumnHeaders.actions,fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-row space-between"},[_c('pui-button',{attrs:{"state":"secondary","small":"","icon":"edit"},on:{"click":function($event){return _vm.openUseCaseModal(row)}}},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]),_c('pui-button',{attrs:{"state":"secondary","small":"","icon":"delete"},on:{"click":function($event){return _vm.openConfirmationModal(row)}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])],1)]}}],null,true)}),_c('use-case-modal',{ref:"useCaseDetails",attrs:{"users":_vm.users,"useCases":_vm.useCases},on:{"save":_vm.useCaseModalSave}}),_c('confirm-modal',{ref:"delete-confirmation-dialog",on:{"confirm":_vm.removeUseCase}})],1):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }