<template>
    <div>
        <pui-form-group
            :label="name"
            :showRequiredLabel="isRequired"
            :is-valid="!isInvalid"
        >
            <pui-form-multi-select
                label=""
                :options="selectBoxOptions"
                :value="inputValue"
                :is-readonly="false"
                :is-disabled="false"
                :is-valid="!isInvalid"
                :required="true"
                :has-searchable-secondary-labels="true"
                @change="onChange"
            />
            <template #error-message>
                {{ error }}
            </template>
        </pui-form-group>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

type Option = {
    disabled?: boolean;
    label: string;
    secondaryLabel?: string;
    selected?: boolean;
    value: number;
};

@Component({
    name: 'multi-select',
})

export default class MultiSelect extends Vue {
    @Prop({required: false, default: [] })
    inputValue!: any;

    @Prop({required: false, default: {} })
    rules!: any;

    @Prop({required: false, default: '' })
    name!: any;

    @Prop({required: false })
    isInvalid!: boolean;

    @Prop({required: false })
    isRequired!: boolean;

    @Prop({required: false })
    error!: string;

    @Prop({default: () => []})
    readonly selectBoxOptions!: Array<any>;

    @Prop({required: false })
    readonly placeholder!: string;

    onChange(newValues: Option[]): void {
        this.$emit('change', newValues);
    }

    get value(): string {
        return this.inputValue;
    }

    get markAsError(): boolean {
        return this.isInvalid && this.rules?.required;
    }
}
</script>

<style lang="less" scoped>
/deep/ .pui-form-group {
    .pui-form-group__label-wrap {
        label {
            padding-top: 1.6rem;
        }
    }
}

/deep/ .pui-multiselect{
    .pui-multiselect__label-wrap {
        display: none;
    }
}
/deep/ .pui-form-type-ahead {
    .pui-chip__text {
        font-size: 1.4rem;
    }
}
</style>
