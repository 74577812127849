<template>
    <div class="flex-row flex-center tags-cotainer">
        <div
            class="flex-row"
            v-for="(tag,index) in tags"
            :key="index"
        >
            <text-input
                class="mr-2"
                :inputValue="tag"
                :iconName="'close'"
                :isInvalid="isInvalid"
                :error="error"
                @iconClicked="handleRemoveTag(index)"
                @change="value => handleTagInputChange(value, index)"
            />
        </div>
        <pui-link
            :class="isInvalid ? 'button-margin' : ''"
            :title="$t('add')"
            variant="primary"
            @click="handleAddNewTag"
        />
    </div>
</template>


<script lang="ts">
import { Component, Vue, Prop} from 'vue-property-decorator';
import TextInput from '../shared/text-input.vue';

@Component({
    name: 'tags-input',
    components: {
        TextInput,
    },
})

export default class TagsInput extends Vue {

    @Prop({ required: true })
    private initialTags!: string[];

    @Prop({required: false })
    isInvalid!: boolean;

    @Prop({required: false })
    error!: string;

    private tags: string[] = [];

    private mounted(): void {
        this.tags = this.initialTags;
    }

    private handleTagInputChange(value: string, index: number): void {
        this.tags[index] = value;
        this.$emit('change', this.tags);
    }

    private handleAddNewTag(): void{
        this.tags.push('');
    }

    private handleRemoveTag(index: number): void {
        if(this.tags.length > 1) {
            this.tags.splice(index, 1);
            const nonEmptyTags = this.tags.filter((tag: string) => tag.length);
            const newTags = nonEmptyTags.length ? nonEmptyTags : ['']
            this.$emit('change', newTags);
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .pui-form-group {
    .pui-form-input-field {
        min-width: 18rem;
    }

    .pui-form-group__content{
        margin-top: 0.4rem;
    }
}

/deep/.pui-form-group__label-wrap {
    label {
        display: none;
    }
}

.tags-cotainer {
    flex-wrap: wrap;
    width: 100%;
}
.button-margin {
    margin-bottom: 1.4rem;
}
</style>
