<template>
    <button
        class="menu-item"
        :class="{ 'is-active': isActive ? isActive() : null }"
        @click="action"
        :title="title"
    >
        <span>
            <font-awesome-icon :icon="icon" />
            <sub v-if="title === 'Heading 1'">1</sub>
            <sub v-if="title === 'Heading 2'">2</sub>
        </span>
    </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faBold,
    faItalic,
    faStrikethrough,
    faHeading,
    faParagraph,
    faListUl,
    faListOl,
    faGripLines,
    faRemoveFormat,
    faUndo,
    faRedo,
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faBold as any,
    faItalic  as any,
    faStrikethrough  as any,
    faHeading as any,
    faParagraph as any,
    faListUl as any,
    faListOl as any,
    faGripLines as any,
    faRemoveFormat as any,
    faUndo as any,
    faRedo as any
);

@Component
export default class EditorTool extends Vue {
  @Prop({ required: true })
  icon!: any;

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  action!: any;

  @Prop({ required: false })
  isActive!: any;
}
</script>

<style lang="less">
@import '../../variables.less';
.menu-item {
  color: @dark-grey;
  border: none;
  background-color: transparent;
  border-radius: 0.4rem;
  margin-right: 0.25rem;

  svg {
    fill: currentColor;
  }

  &.is-active,
  &:hover {
    color: #FFF;
    background-color: #0D0D0D;
  }

  sub {
      font-weight: bold;
  }
}
</style>
